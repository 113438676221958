import { WbfPolicies } from '@workers-benefit-fund/shared-data'
import cx from 'classnames'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Button,
  Checkbox,
  Form,
  Icons,
  Input,
  InputDate,
  Modal,
  SubmitButton,
} from 'src/common/components'
import {
  capitalize,
  defaultEnrollmentTransitions,
  formatDate,
  mapEnrollmentStatus,
} from 'src/common/helpers'
import { IModal } from 'src/common/interfaces'
import { NYC_ORGANIZATION_ID } from 'src/default/config'
import { useMutatePersonEnrollment } from 'src/default/hooks'
import {
  EnumEnrollmentActiveStatus,
  EnumEnrollmentInactiveStatus,
  EnumEnrollmentStatus,
  IEnrollment,
} from 'src/default/interfaces'
import { SingleAccountModalAttachments } from 'src/default/pages/Accounts/SingleAccountPage/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../../v2/commons/HOC/policyCheckHOC'
import { useAppSelector } from '../../../../../../v2/store/hooks'
import ConvertLead from './ConvertLead/ConvertLead'
import SingleAccountEnrollmentsModalStatus from './SingleAccountEnrollmentsModalStatus'
import styles from './single-account-enrollments-modal.module.scss'

interface SingleAccountEnrollmentsModalProps extends IModal {
  data: IEnrollment
  leadName?: string
  tlcNumber?: string
  refetch: () => Promise<any>
}

const DRIVERS_UNION_ID = '1f0636ef-588f-4dbd-ab55-114750af7dc2'

const SingleAccountEnrollmentsModal = (
  props: SingleAccountEnrollmentsModalProps,
) => {
  const { data, leadName, tlcNumber, refetch, onClose } = props

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id

  const { personId } = useParams<{ personId: string }>()

  const [isEditMode, setIsEditMode] = useState(false)
  const [isChanged, setIsChanged] = useState(false)

  const personEnrollmentMutation = useMutatePersonEnrollment('update')

  const ABCOrganizationId = data.associatedBenefitContract.organization.id
  const isNYCOrganization = ABCOrganizationId === NYC_ORGANIZATION_ID

  const currentEnrollmentStatus = mapEnrollmentStatus(
    data.status,
    ABCOrganizationId,
  )

  const formik = useFormik({
    initialValues: { ...data, status: currentEnrollmentStatus },
    onSubmit: async (values) => {
      setIsEditMode(false)

      await personEnrollmentMutation
        .mutateAsync({
          status: values.status,
          isVerified: values.isVerified,
          isActive: values.isActive,
          reasonsForDeactivation: values.statusReason,
          startDate: values.startDate || undefined,
          expirationDate: values.expirationDate || undefined,
          associatedBenefitContractId: values.associatedBenefitContract.id,
          attachments: values.attachments,
          id: values.id,
          personId,
          hasAgreedWithPolicies: values.hasAgreedWithPolicies,
        })
        .then(() => {
          if (onClose) {
            onClose()
          }
        })
    },
  })

  const handleCancelEditMode = () => {
    formik.setValues({ ...data })
    setIsEditMode(false)
    setIsChanged(false)
  }

  const getAllowedTransitionsComputedValues = (
    allowedTransitionsComputed: EnumEnrollmentStatus[],
  ) => {
    const transformedStatuses = allowedTransitionsComputed.filter(
      (transition) => transition !== 'ACTIVE',
    )

    if (isNYCOrganization) {
      transformedStatuses
        .filter((transition) => transition !== 'EXPIRED')
        .map((enrollment) => mapEnrollmentStatus(enrollment, ABCOrganizationId))
      return Array.from(new Set(transformedStatuses))
    }

    return transformedStatuses
  }

  const getAllowedTransitionsForLeads = (
    allowedTransitionsComputed: EnumEnrollmentStatus[],
  ) => {
    const generalLeadsEnrollmentStatus = [
      EnumEnrollmentInactiveStatus.INITIAL,
      EnumEnrollmentInactiveStatus.REVIEW_NEEDED,
      EnumEnrollmentInactiveStatus.INVALID_LICENSE,
      EnumEnrollmentInactiveStatus.NAME_MISMATCH,
    ] as EnumEnrollmentStatus[]

    return generalLeadsEnrollmentStatus.includes(currentEnrollmentStatus)
      ? defaultEnrollmentTransitions
      : allowedTransitionsComputed
  }

  const getAllowedTransitionsForAccounts = (
    allowedTransitionsComputed: EnumEnrollmentStatus[],
  ): EnumEnrollmentStatus[] => {
    switch (currentEnrollmentStatus) {
      case EnumEnrollmentActiveStatus.ACTIVE:
        return defaultEnrollmentTransitions

      case EnumEnrollmentActiveStatus.LICENSE_GRACE_PERIOD:
      case EnumEnrollmentActiveStatus.DRIVING_HOURS_APPEAL_PERIOD:
        return [
          ...defaultEnrollmentTransitions,
          EnumEnrollmentActiveStatus.ACTIVE,
        ]

      case EnumEnrollmentInactiveStatus.LICENSE_GRACE_PERIOD_DEACTIVATED:
        return [
          ...defaultEnrollmentTransitions,
          EnumEnrollmentActiveStatus.LICENSE_GRACE_PERIOD_REACTIVATED,
        ]

      case EnumEnrollmentInactiveStatus.DRIVING_HOURS_DEACTIVATED:
        return [
          ...defaultEnrollmentTransitions,
          EnumEnrollmentActiveStatus.DRIVING_HOURS_REACTIVATED,
        ]

      case EnumEnrollmentInactiveStatus.OPT_OUT:
        return [EnumEnrollmentActiveStatus.ACTIVE]

      case EnumEnrollmentActiveStatus.LICENSE_GRACE_PERIOD_REACTIVATED:
      case EnumEnrollmentActiveStatus.DRIVING_HOURS_REACTIVATED:
      case EnumEnrollmentInactiveStatus.EXISTING_PERSON:
      case EnumEnrollmentInactiveStatus.NOT_ELIGIBLE:
        return []

      default:
        return allowedTransitionsComputed
    }
  }

  const getAllowedTransitions = () => {
    const allowedComputedTransitions = getAllowedTransitionsComputedValues(
      data.allowedTransitionsComputed,
    )

    if (!isNYCOrganization) {
      return allowedComputedTransitions
    }

    if (!data.isVerified) {
      return getAllowedTransitionsForLeads(allowedComputedTransitions)
    }
    return getAllowedTransitionsForAccounts(allowedComputedTransitions)
  }

  const getSingleAccountEnrollmentsComponent = () => {
    const isDisabledInput =
      (!data.isVerified &&
        defaultEnrollmentTransitions.includes(currentEnrollmentStatus)) ||
      (data.isVerified &&
        defaultEnrollmentTransitions
          .slice(0, 2)
          .includes(currentEnrollmentStatus))

    if (isNYCOrganization && isDisabledInput) {
      return (
        <Input
          name="status"
          label="Enrollment Status"
          disabled
          value={capitalize(currentEnrollmentStatus)}
          withError={false}
        />
      )
    }

    return (
      <SingleAccountEnrollmentsModalStatus
        name="status"
        required
        disabled={!isEditMode}
        label="Enrollment Status"
        currentEnrollmentStatus={currentEnrollmentStatus}
        allowedStatuses={getAllowedTransitions()}
      />
    )
  }

  return (
    <Modal id="singleAbcModal" template="big" onClose={onClose}>
      <Form className={styles.wrapper} providerValue={formik}>
        <div className={styles.title}>Associated Benefit Contract Details</div>

        <div className={styles.subtitleWrapper}>
          <div className={styles.subtitle}>
            Associated Benefit Contract Details
          </div>
          {!data.isVerified && (
            <ConvertLead
              personId={personId ?? ''}
              enrollmentId={data.id}
              leadName={leadName}
              tlcNumber={tlcNumber}
              refetch={refetch}
            />
          )}
        </div>

        <div className={styles.block}>
          <Input
            name="associatedBenefitContract"
            label="Contract"
            disabled
            value={data.associatedBenefitContract.name}
          />

          <Checkbox name="isVerified" label="Verified" disabled />
        </div>

        <div
          className={cx(
            styles.subtitleWrapper,
            styles.subtitle,
            styles.enrollmentSubtitle,
          )}
        >
          <span>Enrollment Information</span>

          {!isEditMode && currentOrganizationId !== DRIVERS_UNION_ID ? (
            <PolicyCheckHOC policyName="is_update_enrollment_enabled">
              <div
                className={styles.updateButton}
                onClick={() => setIsEditMode(true)}
              >
                <Icons.Edit />
                Update
              </div>
            </PolicyCheckHOC>
          ) : null}
        </div>

        <div className={styles.firstBlock}>
          {getSingleAccountEnrollmentsComponent()}

          <Checkbox
            className={styles.isActiveCheckbox}
            name="isActive"
            disabled={true}
            label="Is Active"
          />

          {userProfile.policies.includes(
            //add ts-ignore until update the share-date witn the last version
            //@ts-ignore
            WbfPolicies.is_has_agreed_with_policies_enabled,
          ) ? (
            <Checkbox
              className={styles.isActiveCheckbox}
              name="hasAgreedWithPolicies"
              disabled={!isEditMode}
              label="Agree With Policies"
            />
          ) : null}
        </div>
        {!!data?.statusReason && data?.statusReason != '' ? (
          <div className={styles.block}>
            <Input
              name="statusReason"
              disabled
              label="Status Details"
              value={data?.statusReason}
              withError={false}
            />
          </div>
        ) : null}

        <div className={styles.block}>
          <Input
            name="verifiedBy"
            disabled
            label="Enrolled By"
            value={
              `${data.verifiedBy?.person.firstName || ''} ${
                data.verifiedBy?.person.lastName || ''
              }` +
              (!!data.verificationSource ? ` (${data.verificationSource})` : '')
            }
            withError={false}
          />
          <InputDate
            name="startDate"
            disabled
            label="Enrollment Start Date"
            futureDate
            withError={false}
          />
        </div>

        <div className={styles.block}>
          <InputDate
            name="expirationDate"
            disabled={!isEditMode}
            label="Enrollment End Date"
            futureDate
            withError={false}
          />

          <Input
            name="enrollmentStatusLastModifiedDate"
            disabled
            label="Enrollment Status Last Modified Date"
            value={
              data.enrollmentStatusLastModifiedDate
                ? formatDate(
                    data.enrollmentStatusLastModifiedDate,
                    'MM/DD/YYYY HH:mm A',
                  )
                : ''
            }
            withError={false}
          />
        </div>

        <div className={styles.block}>
          <Input
            name="enrollmentStatusLastModifiedBy"
            disabled
            label="Enrollment Status Last Modified By"
            value={`${
              data.enrollmentStatusLastModifiedBy?.person.firstName || ''
            } ${data.enrollmentStatusLastModifiedBy?.person.lastName || ''}`}
            withError={false}
          />

          <Input
            name="previousStatus"
            disabled
            label="Previous Enrollment Status"
            value={capitalize(data?.previousStatus || '-')}
            withError={false}
          />
        </div>

        <SingleAccountModalAttachments
          name="attachments"
          attachmentType="enrollment"
          isEditMode={isEditMode || currentOrganizationId === DRIVERS_UNION_ID}
          onChange={() => setIsChanged(true)}
          multiple
        />

        <div className={styles.footer}>
          {isEditMode || isChanged ? (
            <>
              <Button
                className={styles.footerButton}
                onClick={handleCancelEditMode}
              >
                Cancel
              </Button>

              <SubmitButton
                className={styles.footerButton}
                iconAfter="ArrowSelect"
              >
                Save
              </SubmitButton>
            </>
          ) : null}
        </div>
      </Form>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleAccountEnrollmentsModal,
  'is_enable_single_account_enrollment_detail',
  true,
)
